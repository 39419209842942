import React, { useState, useContext } from 'react';
import { NavContext } from 'providers/NavProvider';
import useAuth from 'hooks/useAuth';

import SideBar from './SideBar';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

import useStyles from './styles';

function NavBar() {
  const [open, setOpen] = useState(false);
  const { title, rightButton } = useContext(NavContext)
  const styles = useStyles();
  const { accessToken } = useAuth();

  const toggleDrawer = () => {
    setOpen(!open);
  }

  return (
    <AppBar position="static">
      <Toolbar>
        {accessToken && 
          <IconButton 
            edge="start" 
            className={styles.menuButton} 
            color="inherit" 
            aria-label="menu" 
            onClick={toggleDrawer}
          >
            <MenuIcon />
          </IconButton>
        }
        <Typography variant="h6" className={styles.title}>
          {title}
        </Typography>
        {!!rightButton && <Button color="inherit">Login</Button>}
      </Toolbar>
      <SideBar open={open} toggleDrawer={toggleDrawer} />
    </AppBar>
  )
}

export default NavBar;